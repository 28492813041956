import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faSpinner, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';

import { SCHEDULE_QUERY, WAITRESSES_QUERY, RECALCULATE_MUTATION, ADD_COMMENT_MUTATION } from '../Apollo';
import { useFormFields } from '../libs/hooksLib';
import LoaderButton from '../components/LoaderButton';

import './Home.css';
import { onError } from '../libs/errorLib';


const DAYS = ['Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'];
const SHIFTS = ['Otevírá', '11-20', 'Zavírá'];


function WaitressSelect({ actualSchedule, selectedShift, waitresses, recalculate }) {
  const { day, shift, activate } = selectedShift;
  const [showDialog, setShowDialog] = useState(false);
  const [waitress, setWaitress] = useState(null);

  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => {
    setWaitress(actualSchedule[day][shift]);
    setShowDialog(true);
  }

  useEffect(() => {
    if (day !== null && shift !== null) {
      handleShowDialog();
    }
  }, [activate]);

  function handleSubmit(event) {
    event.preventDefault();
    const schedule = [...actualSchedule];  // TODO deep copy
    schedule[day][shift] = waitress;
    recalculate({ variables: { schedule } });
    handleCloseDialog();
  }

  return (
    <Modal show={ showDialog } onHide={ handleCloseDialog }>
      <Modal.Header closeButton>
        <Modal.Title>{ DAYS[day] } – { SHIFTS[shift] }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { waitresses.map(name =>
          <Form.Check
            id={ `check-${name}` }
            key={ name }
            type="radio"
            label={ name }
            name="waitress"
            checked={ waitress == name }
            onChange={ () => setWaitress(name) }
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ handleCloseDialog }>
          Zrušit
        </Button>
        <Button variant="primary" onClick={ handleSubmit }>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


export default function Home() {
  const { loading, error, data, refetch } = useQuery(SCHEDULE_QUERY);
  const { data: waitressesData } = useQuery(WAITRESSES_QUERY);
  const [generating, setGenerating] = useState(false);
  const [selectedShift, setSelectedShift] = useState({
    day: null,
    shift: null,
    activate: null,
  });

  function handleSelectShift(day, shift) {
    return (event) => {
      setSelectedShift({ day, shift, activate: Date.now() });
    };
  }

  function checkTd(day, shift) {
    const waitress = schedule[day][shift];
    if (schedule[day].reduce((n, x) => n + (x == waitress), 0) > 1) {
      return 'text-danger';
    }
  }

  function handleRegenerate(event) {
    event.preventDefault();
    setGenerating(true);
    refetch({ generate: true }).then(() => {
      setGenerating(false);
    });
  }

  const [recalculate, { data: updatedData }] = useMutation(RECALCULATE_MUTATION);

  function handleSave(event) {
    event.preventDefault();
    recalculate({ variables: { schedule, save: true } });
  }

  function handleCancelChanges(event) {
    event.preventDefault();
    // TODO discard updatedData
    window.location.reload();
  }

  const [fields, handleFieldChange, setFormValues] = useFormFields({
    comment: '',
  });

  const [addComment, { loading: addCommentLoading }] = useMutation(
    ADD_COMMENT_MUTATION,
    {
      onCompleted() {
        toast('Komentář přijat, děkuji!');
        setFormValues({ comment: '' });
      },
      onError() {
        toast('Komentář se nepodařilo odeslat. :-(');
      }
    }
  );

  function handleCommentSubmit(event) {
    event.preventDefault();
    addComment({ variables: {
      scheduleId: scheduleId,
      text: fields.comment,
    } });
  }

  function validateCommentForm() {
    return fields.comment.trim().length > 0;
  }

  const isUpdated = updatedData && !updatedData.recalculate.id;
  const schedule =
    (updatedData && updatedData.recalculate.schedule)
    || (data && data.schedule.schedule);
  const scheduleId =
    (updatedData && updatedData.recalculate.id)
    || (data && data.schedule.id);
  const hours =
    (updatedData && updatedData.recalculate.hours)
    || (data && data.schedule.hours);
  const penalty =
    (updatedData && updatedData.recalculate.penalty)
    || (data && data.schedule.penalty);

  return (
    <div className="Home">
      <div className="lander">
        <h1>2 promile</h1>
        <p>Plánovač směn</p>

        <p>
          <Button disabled={ generating } onClick={ handleRegenerate }>
            { !generating &&
              <>
                <FontAwesomeIcon icon={ faRedo } />
                Nový návrh
              </>
            }
            { generating &&
              <>
                <FontAwesomeIcon icon={ faSpinner } spin />
                Vytvářím...
              </>
            }
          </Button>

          { isUpdated &&
            <>
              <Button onClick={ handleSave }>
                <FontAwesomeIcon icon={ faSave } />
                Uložit
              </Button>
              <Button onClick={ handleCancelChanges }>
                <FontAwesomeIcon icon={ faTimes } />
                Zahodit změny
              </Button>
            </>
          }
        </p>

        { waitressesData && schedule &&
          <WaitressSelect
            actualSchedule={ schedule }
            selectedShift={ selectedShift }
            waitresses={ waitressesData.waitresses }
            recalculate={ recalculate }
          />
        }

        { loading && !generating && <p>Načítám...</p> }
        { error && <p>Chyba :-(</p> }
        { !generating && data &&
          <>
            <Table>
              <thead>
                <tr>
                  <th>Den</th>
                  <th>{ SHIFTS[0] }</th>
                  <th>{ SHIFTS[1] }</th>
                  <th>{ SHIFTS[2] }</th>
                </tr>
              </thead>
              <tbody>
                { schedule.map((day, index) =>
                    <tr>
                      <th>{ DAYS[index] }</th>
                      { [0, 1, 2].map(shift =>
                        <td className={ checkTd(index, shift) }
                            onClick={ handleSelectShift(index, shift) }>
                          { day[shift] }
                        </td>
                      )}
                    </tr>
                ) }
              </tbody>
            </Table>

            <hr />

            <Table>
              <thead>
                <tr>
                  <th>Jméno</th>
                  <th>Hodin</th>
                </tr>
              </thead>
              <tbody>
                { hours.map(({ name, hours}) =>
                  <tr>
                    <td>{ name }</td>
                    <td>{ hours }</td>
                  </tr>
                ) }
              </tbody>
            </Table>

            <hr />

            <p>
              Chyba: { penalty }
            </p>

            { !isUpdated &&
              <Form onSubmit={ handleCommentSubmit }>
                <Form.Group controlId="comment">
                  <Form.Label>Komentář:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    value={ fields.comment }
                    onChange={ handleFieldChange }
                  />
                </Form.Group>
                <LoaderButton
                    type="submit"
                    isLoading={ addCommentLoading }
                    disabled={ !validateCommentForm() }
                  >
                  Odeslat
                </LoaderButton>
              </Form>
            }
            { isUpdated &&
              <p>Pouze uložené rozvrhy mohou být komentovány.</p>
            }
          </>
        }
      </div>
    </div>
  );
}

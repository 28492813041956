import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';
import { ToastContainer } from 'react-toastify';
import { I18nProvider } from '@lingui/react';
import { Trans } from '@lingui/macro';

import { AppContext } from './libs/contextLib';
import { onError } from './libs/errorLib';
import Routes from './Routes';
import LanguageSelect from './components/LanguageSelect';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';


function App() {
  const history = useHistory();
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      if (localStorage.getItem('access_token')) {
        userHasAuthenticated(true);
      }
    }
    catch(e) {
      onError(e);
    }

    setIsAuthenticating(false);
  }

  function handleLogout() {
    localStorage.removeItem('access_token');
    userHasAuthenticated(false);
    history.push('/login');
  }

  return (
    !isAuthenticating &&
    <div className="App container">
      <ToastContainer />

      {/* <Navbar collapseOnSelect bg="light">
        <Navbar.Brand>
          <Link to="/"><Trans>Home</Trans></Link>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="ml-auto">
            <LanguageSelect />
            {isAuthenticated
              ? <Nav.Link onClick={ handleLogout }><Trans>Logout</Trans></Nav.Link>
              : <>
                  <LinkContainer to="/login">
                    <Nav.Link><Trans>Login</Trans></Nav.Link>
                  </LinkContainer>
                </>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar> */}

      <AppContext.Provider value={{
          isAuthenticated, userHasAuthenticated,
          }}>
        <Routes />
      </AppContext.Provider>
    </div>
  );
}

function AppI18n() {
  const [languageCode, setLanguageCode] = useState(
    new URLSearchParams(useLocation().search).get('lang')
    || localStorage.getItem('languageCode')
    || 'cs'
  );

  useEffect(() => {
    localStorage.setItem('languageCode', languageCode);
  }, [languageCode]);

  return (
    <AppContext.Provider value={{ languageCode, setLanguageCode }}>
      <I18nProvider language={ languageCode }>
        <App />
      </I18nProvider>
    </AppContext.Provider>
  );
}

export default AppI18n;

import React from 'react';
import { Trans } from '@lingui/macro';

import './NotFound.css';

export default function NotFound() {
  return (
    <div className="NotFound">
      <h3><Trans id="app.not-found">Sorry, page not found!</Trans></h3>
    </div>
  );
}
